<template>
  <div>
    <div class="info">
      <div>
        <el-tabs value="first" @tab-click="handleClick">
          <el-tab-pane label="楼盘地图" name="first">
            <div>楼盘总数：</div>
            <el-descriptions
              class="margin-top"
              :column="4"
              :size="size"
              border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  普通住宅
                </template>
                kooriookami
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  公寓
                </template>
                18100000000
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  别墅
                </template>
                苏州市
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  商业
                </template>
                <el-tag size="small">学校</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  办公
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  工业
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  农业
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  特殊
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="客户地图" name="second">
            <div>客户总数：</div>
            <el-descriptions
              class="margin-top"

              :column="4"
              :size="size"
              border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  物业
                </template>
                kooriookami
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  房楼盘开发商
                </template>
                18100000000
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  城投
                </template>
                苏州市
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  学校
                </template>
                <el-tag size="small">学校</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  医院
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  业委会
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="维保项目地图" name="third">
            <div style="display: flex">
              <div>项目总数：</div>
              <div>电梯总数：</div>
            </div>

            <el-descriptions
              class="margin-top"
              :column="4"
              :size="size"
              border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  别墅
                </template>
                苏州市
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  商业
                </template>
                <el-tag size="small">学校</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  办公
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  工业
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="工单地图" name="four">
            <div>当前工单总数：</div>
            <el-descriptions 
              class="margin-top"
              :column="6"
              :size="size"
              border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  待派单数量
                </template>
                kooriookami
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  进行中数量
                </template>
                18100000000
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  别墅
                </template>
                苏州市
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  待评价数量
                </template>
                <el-tag size="small">学校</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  保养单数量
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  急修单数量
                </template>
                江苏省苏州市吴中区吴中大道 1188 号
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
        </el-tabs>
        <div style="position: absolute;right:20px;top:20px;width: 300px;height: 54px;line-height: 54px;z-index: 1000">数据来源:<el-select style="width: 200px"></el-select></div>
        <div style="display: flex">
          <div style="display:flex">
            <div :style="{width:width+'px'}" class="tree">
              <el-autocomplete style="width: 100%"></el-autocomplete>
              <el-tree
                :data="list"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :render-content="renderContent"
                @node-click="handleNodeClick"></el-tree>
            </div>
            <div v-show="show" :class="draw">
              <div class="header">
                <div class="title">
                  {{title}}
                </div>
                <el-button
                  v-if="drawStatus==='收起'"
                  class="drawButton"
                  type="primary"
                  plain
                  @click="open">
                  {{drawStatus}}
                </el-button>
              </div>
              <div class="contentBox">
                <ul class="infinite-list">
                  <li v-for="item in elevatorList" :key="item.id" :class="selectId===item.id?'content content-active':'content'" @click="onSelect(item)">
                    <div class="zhanshi">
                      <div :class="selectId===item.id?'name name-active':'name'">
                        {{item.id}}
                      </div>
                      <div :class="selectId===item.id?'area area-active':'area'">
                        {{item.buildingName}}
                      </div>
                    </div>
                    <div :class="selectId===item.id?'el-icon el-icon-active':'el-icon'">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </li>
                </ul>
                <div v-if="drawStatus==='收起'">
                  <ul>
                    <li>楼盘名称：{{detail.realEstateName}}</li>
                    <li>楼宇名称：{{detail.buildingName}}</li>
                    <li>电梯名称/编号：{{detail.name}}</li>
                    <li>电梯厂家：{{detail.manufacturerName}}</li>
                    <li>电梯品牌：{{detail.brandName}}</li>
                    <li>电梯型号：{{detail.modelName}}</li>
                    <li>出厂编号：{{detail.factoryCode}}</li>
                    <li>出厂日期：{{detail.factoryDate}}</li>
                    <li>控制系统：{{detail.controlSystemDesc}}</li>
                    <li>注册代码：{{detail.regCode}}</li>
                    <li>使用登记证编号：{{detail.useCode}}</li>
                    <li>救援识别码：{{detail.rescueCode}}</li>
                    <li>应急救援电话：{{detail.rescuePhone}}</li>
                    <li>开始使用日期：{{detail.useDate}}</li>
                    <li>使用场所分类：{{detail.usePlaceCategory}}</li>
                    <li>使用类型：{{detail.useTypeDesc}}</li>
                    <li>使用状态：{{detail.useStatusDesc}}</li>
                    <li>电梯层数：{{detail.totalFloor}}</li>
                    <li>电梯站数：{{detail.totalStation}}</li>
                    <li>电梯门数：{{detail.totalDoor}}</li>
                    <li>电梯额定速度(m/s)：{{detail.ratedSpeed}}</li>
                    <li>电梯额定载重量（KG）：{{detail.ratedLoads}}</li>
                    <li>起升高度(单位米)：{{detail.liftingHeight}}</li>
                    <li>开门方式：{{detail.doorOpenWay}}</li>
                    <li>开门尺寸(单位毫米)：{{detail.doorWidth}}</li>
                    <li>顶层高度(单位米)：{{detail.topHeight}}</li>
                    <li>底坑高度(单位米)：{{detail.pitHeight}}</li>
                    <li>名义宽度（mm）：{{detail.nominalWidth}}</li>
                    <li>倾斜角（°）：{{detail.angle}}</li>
                    <li>是否有机房：{{detail.machineRoom?"是":"否"}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="map"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MapLoader from "@/util/map";
  import {getWidth} from "@/util";
  // import Vue from "vue";
  // import ElevatorMapInfoWindow from "@/views/elevator/ElevatorMapInfoWindow";
  // import {bj} from "./beijing";

  let AMap;

  export default {
    components: {},
    data() {
      return {
        map: null,
        elevatorCount: 0,
        wbOverdueCount: 0,
        exportWbLoading: false,
        AMap:null,
        show:false,
        draw:"draw",
        drawStatus:"展开",
        title:"",
        data:[],
        list:[],
        selectId:0,
        elevatorList:[],
        satelliteLayer:null,//卫星图层
        detail:{},
        districtCode:"",
        customerCompanyId:"",
        makerInfo:{
          name:"电梯1",
          content:"内容1",
          img:"http://tpc.googlesyndication.com/simgad/5843493769827749134",
          person:"周宇",
          phone:123456,
          area:"威海市经济技术开发区九龙城智慧谷",
          path:"#/elevator/Elevatordetail?elevatorId=",
          id:58,
        },
        clusterIndexSet: {
          city: {
            minZoom: 2,
            maxZoom: 10,
          },
          district: {
            minZoom: 10,
            maxZoom: 12,
          },
          area: {
            minZoom: 12,
            maxZoom: 15,
          },
          community: {
            minZoom: 15,
            maxZoom: 22,
          },
        },
      };
    },
    mounted() {
      this.initMap();
      this.getTreeList();

    },
    methods: {
      initMap() {
        MapLoader().then(value => {
          AMap = value;
          this.AMap = value;
          this.map = new AMap.Map("map", {
            resizeEnable: true,
            // pitch:75, // 地图俯仰角度，有效范围 0 度- 83 度
            // viewMode:"3D", // 地图模式
          });
          this.map.addControl(new AMap.ToolBar());
          this.map.addControl(new AMap.MapType());
          // this.satelliteLayer = new AMap.TileLayer.Satellite();
          // this.map.add(this.satelliteLayer);
          const arr= [];
          this.$http.get("work-map/maintenance-project?districtCode=" + this.districtCode + "&customerCompanyId=" + this.customerCompanyId).then(res => {
            this.elevatorCount = res.length;
            res.forEach(item=>{
              arr.push({elevatorQuantity:item.elevatorQuantity,id:item.id,name:item.name,"lnglat":[item.longitude,item.latitude]});
            });
            var cluster = new AMap.MarkerClusterer(this.map, arr, {
              gridSize: 190, // 聚合网格像素大小
              minClusterSize:1,
              renderClusterMarker: this._renderClusterMarker, // 自定义聚合点样式
              renderMarker: this._renderMarker,   // 自定义非聚合点样式
            });
            cluster.on("click", e => {
              if (e.clusterData.length===1) {
                this.show = true;
                this.makerInfo = e.clusterData[0];
                this.openInfoWindow(e.clusterData[0].lnglat, e.clusterData.id);
              }

              // this.makerInfo = item;

            });
            this.map.add(cluster);
          });
          this.data = arr;

          // this.getPoints();
        });
      },
      getPoints() {
        this.$http.get("work-map/maintenance-project?districtCode=" + this.districtCode + "&customerCompanyId=" + this.customerCompanyId).then(data => {
          let points = data;
          this.data = data;
          this.elevatorCount = points.length;
          let markers = [];
          // let cluster = [];
          let arr = [];
          points.forEach(item => {
            if (item.longitude && item.latitude) {
              let marker = new AMap.Marker({
                position:new AMap.LngLat(item.longitude, item.latitude),
                icon: "https://s1.ax1x.com/2020/11/06/BWzNyF.png",
                offset: new AMap.Pixel(-12, -33),
                lnglat:[item.longitude,item.latitude],
              });
              marker.on("click", e => {
                this.makerInfo = item;
                this.show = true;
                this.openInfoWindow(e.target.getPosition(), item.id);
              });

              arr.push({weight:1,name:item.name,"lnglat":[item.longitude,item.latitude]});

              markers.push(marker);
            }
          });

          let count = markers.length;
          // 设置聚合样式，这里都是聚合样式的配置
          let _renderClusterMarker = function(context) {
            let factor = Math.pow(context.count / count, 1 / 18);
            let div = document.createElement("div");
            let Hue = 180 - factor * 180;
            let bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
            let fontColor = "hsla(" + Hue + ",100%,20%,1)";
            let borderColor = "hsla(" + Hue + ",100%,40%,1)";
            let shadowColor = "hsla(" + Hue + ",100%,50%,1)";
            div.style.backgroundColor = bgColor;
            let size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
            div.style.width = div.style.height = size + "px";
            div.style.border = "solid 1px " + borderColor;
            div.style.borderRadius = size / 2 + "px";
            div.style.boxShadow = "0 0 1px " + shadowColor;
            div.innerHTML = context.count;
            div.style.lineHeight = size + "px";
            div.style.color = fontColor;
            div.style.fontSize = "14px";
            div.style.textAlign = "center";
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
            context.marker.setContent(div);
          };

          // 调用函数添加点聚合功能

          // if (cluster) {
          //   cluster.setMap(null);
          // } else {
          // 如果不设置renderClusterMarker，聚合点为默认样式
          let cluster =new AMap.MarkerClusterer(this.map, markers, {
            gridSize: 190, // 聚合网格像素大小
            minClusterSize:1,
            //  使用上面设置的点聚合样式
            renderClusterMarker: _renderClusterMarker,
          });

          this.map.add(cluster);
          // }

          this.map.add(markers);

          // this.map.setFitView(markers, true, [160, 160, 60, 60]);

        });
      },
      openInfoWindow(position, elevatorId) {
        let routeUrl = `#/monitor/liftMonitor?elevatorId=${elevatorId}`;
        this.map.setZoomAndCenter(16, position);
        this.title = this.makerInfo.name;
        this.getEleList();
        this.info(position, routeUrl);

        // let component = Vue.extend(ElevatorMapInfoWindow);
        // let instance = new component().$mount();
        // instance.initData(elevatorId, routeUrl.href).then(() => {
        //   let infoWindow = new AMap.InfoWindow({
        //     content: instance.$el,
        //   });
        //   infoWindow.open(this.map, position);
        // }).catch(() => {
        //   this.$message.error("无法查询到电梯信息");
        // });

      },
      getEleList() {
        this.$http.get("work-map/maintenance-project/" + this.makerInfo.id).then(res => {
          this.elevatorList = res;
        });
      },
      open() {
        this.filter={};
        if (this.drawStatus === "展开") {
          this.draw = "draw-open";
          this.drawStatus = "收起";
        } else {
          this.draw = "draw-close";
          this.drawStatus = "展开";
        }
      },
      onSelect(item = {}) {
        this.selectId = item.id;
        this.detail = item;
        this.draw = "draw-open";
        this.drawStatus = "收起";
      },
      initDraw() {
        // this.data.forEach({})
      },
      createInfoWindow(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";

        //可以通过下面的方式修改自定义窗体的宽高
        info.style.width = "400px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = this.closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = "white";
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = "relative";
        bottom.style.top = "0px";
        bottom.style.margin = "0 auto";
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
      },
      info(marker) {
        // let img = <img src="this.makerInfo.img"/>
        var title = "名称：" + this.makerInfo.name,
            content = [];
        content.push("<img src=" + this.makerInfo.img + " />台量：" + this.makerInfo.elevatorQuantity + "台");
        // content.push("客户公司：" + this.makerInfo.customerCompanyName);
        // content.push("负责人：" + this.makerInfo.assigneeName);

        // content.push("<a style='margin-left: 3px;border: none' target='_blank' href="+url+">监控</a><a style='border: none' target='_blank' href="+this.makerInfo.path+this.makerInfo.id+">详细信息</a>");

        var infoWindow = new this.AMap.InfoWindow({
          isCustom: true,  //使用自定义窗体
          content: this.createInfoWindow(title, content.join("<br/>")),
          offset: new this.AMap.Pixel(16, -45),
          autoMove: true,
        });

        // 打开信息窗体
        infoWindow.open(this.map, marker);
      },
      closeInfoWindow() {
        this.show = false;
        this.map.clearInfoWindow();
      },
      _renderClusterMarker(context) {
        // 聚合中点个数
        var clusterCount = context.count;
        var div = document.createElement("div");
        // 聚合点配色
        // var defaultColor = [
        //   "204,235,197",
        //   "168,221,181",
        //   "123,204,196",
        //   "78,179,211",
        //   "43,140,190",
        // ];
        // var bgColor;
        // if (clusterCount >= 0 && clusterCount < 10) {
        //   bgColor = defaultColor[0];
        // } else if (clusterCount >= 10 && clusterCount < 100) {
        //   bgColor = defaultColor[1];
        // } else if (clusterCount >= 100 && clusterCount < 1000) {
        //   bgColor = defaultColor[2];
        // } else if (clusterCount >= 1000 && clusterCount < 10000) {
        //   bgColor = defaultColor[3];
        // } else if (clusterCount >= 10000) {
        //   bgColor = defaultColor[4];
        // }

        let factor = Math.pow(context.count / this.elevatorCount, 1 / 18);
        let Hue = 180 - factor * 180;
        let bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
        let fontColor = "hsla(" + Hue + ",100%,20%,1)";
        let borderColor = "hsla(" + Hue + ",100%,40%,1)";
        let shadowColor = "hsla(" + Hue + ",100%,50%,1)";
        div.style.backgroundColor = bgColor;
        // div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
        var size = Math.round(25 + Math.pow(clusterCount / this.data.length, 1 / 5) * 40);
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px rgba(" + bgColor + ",1)";
        div.style.borderRadius = size / 2 + "px";
        div.innerHTML = context.count;
        div.style.lineHeight = size + "px";
        div.style.color = "#ffffff";
        div.style.fontSize = "12px";
        div.style.textAlign = "center";
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px " + borderColor;
        div.style.borderRadius = size / 2 + "px";
        div.style.boxShadow = "0 0 1px " + shadowColor;
        div.innerHTML = context.count;
        div.style.lineHeight = size + "px";
        div.style.color = fontColor;
        div.style.fontSize = "14px";
        div.style.textAlign = "center";
        context.marker.setOffset(new this.AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
        context.marker.on("click", (e) => {
          var curZoom = this.map.getZoom();
          if (curZoom < 20) {
            curZoom += 2;
          }
          this.map.setZoomAndCenter(curZoom, e.lnglat);
        });
      },
      _renderMarker(context) {
        var content = "<div style=\"background-color: rgba(255,255,178,.9); height: 18px; width: 18px; border: 1px solid rgba(255,255,178,1); border-radius: 12px; box-shadow: rgba(0, 0, 0, 1) 0px 0px 3px;\"></div>";
        var offset = new this.AMap.Pixel(-9, -9);
        context.marker.setContent(content);
        context.marker.setOffset(offset);
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http = this.$http.get("/basic/district/tree/locate-and-with-customer-company");
        http.then(data => {
          this.list = data;
          const mapWidth = document.querySelector("#map").clientWidth;

          this.width = 100 + 14 * getWidth(data);
          if (this.width > (mapWidth * 0.2)) {
            this.width = mapWidth * 0.2;
          }
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {
        this.districtCode = "";
        this.customerCompanyId = "";
        if (e.other === "district") {
          this.districtCode = e.value;
        } else {
          this.customerCompanyId = e.value;
        }
        this.show = false;
        this.initMap();
      },
      renderContent(h, {node}) {
        if (node.childNodes instanceof Array && node.childNodes.length>0) {
          return (<span>{node.label}</span>);
        }
        return (
            <span class="custom-tree-node">
              <el-tooltip  className="item" effect="dark" content={node.label} placement="top-start">
              <span>{node.label}</span>
              </el-tooltip>
          </span>);

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped>
  #map {
    height: calc(100vh - 150px);
    width: 100%;
  }
  .info {
    border: 1px solid #EBEEF5;
    margin-bottom: 10px;
    background-color: #f4f7fa;
    padding: 8px 15px;

    .item {
      font-size: 12px;
      display: inline-block;
      margin-right: 8px;
      background-color: #909399;
      color: white;
      line-height: 1;
      border-radius: 1px;
      padding: 7px 15px;
      border: 1px solid #909399;
    }
  }

  .tree{
    height: calc(100vh - 150px);
    width: 190px;
    background-color: white;
    //position: absolute;
    //float: left;
    z-index: 999;
    border: 1px solid #E6E6E6;
  }

  .draw{
    height: calc(100vh - 150px);
    width: 190px;
    background-color: white;
    //position: absolute;
    z-index: 999;
    left: 190px;
    border: 1px solid #E6E6E6;
  }


  .draw-open{
    height: calc(100vh - 150px);
    width: 420px;
    background-color: white;
    //position: absolute;
    z-index: 999;
    left: 190px;
    border: 1px solid #E6E6E6;
    animation-name: draw-open;
    animation-duration: 1s;
  }

  .draw-close{
    height: calc(100vh - 150px);
    width: 190px;
    background-color: white;
    //position: absolute;
    z-index: 999;
    left: 190px;
    border: 1px solid #E6E6E6;
    animation-name: draw-close;
    animation-duration: 1s;
  }

  @keyframes draw-open {
    from{
      width: 190px;
    }
    to{
      width: 420px;
    }
  }
  @keyframes draw-close {
    from{
      width: 420px;
    }
    to{
      width: 190px;
    }
  }

  .header{
    height: 42px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0 10px 16px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .drawButton{
     float: right;
   }

  .title{
    color: #1a1a1a;
    font-weight: 600;
    border-left: 4px solid #3988ff;
    width: calc(100% - 21px) ;
    padding-left: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .infinite-list{
    height:calc(100vh - 192px) ;
    overflow: scroll;
    display: inline-block;
    vertical-align: top;
    width: 188px;
    padding: 15px;
    position: relative;
    background-color: #fff;
    z-index: 20;
  }

  ul::-webkit-scrollbar{
    display:none;
  }

  .content{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .zhanshi{
    flex: 1;
    overflow: hidden;
    padding: 10px;
    width: 100%;
    height: 60px;
  }

  .content-active{
    background-color: #3988ff;
    border-radius: 6px;
  }


  .name{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }
  .area{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }

  .name-active{
    color: white;
  }
  .area-active{
    color: white;
  }

  .el-icon{
    font-family: element-icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
  }
  .el-icon-active{
    color: white;
  }
</style>
<style lang="scss">
  .amap-icon img {
    width: 24px;
    height: 35px;
  }
</style>
<style lang="scss">
.amap-sug-result {
  z-index: 3000 !important;
}
.contentBox{
  display: flex;
}
.amap-icon img {
  width: 24px;
  height: 35px;
}
.content-window-card {
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0;
}

.content-window-card p {
  height: 2rem;
}

.custom-info {
  border: solid 1px silver;
}

div.info-top {
  position: relative;
  background: none repeat scroll 0 0 #F9F9F9;
  border-bottom: 1px solid #CCC;
  border-radius: 5px 5px 0 0;
}

div.info-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.info-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.info-top img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}

div.info-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.info-bottom img {
  position: relative;
  z-index: 104;
}

//span {
//  margin-left: 5px;
//  font-size: 11px;
//}

.info-middle img {
  float: left;
  margin-right: 6px;
}
</style>